import React from 'react';
import '../../css/contact.scss';
import { graphql } from 'gatsby';
import Markdown from '../../components/Markdown';
import Layout from '../../components/Layout';
import HeadingDecorated from '../../components/HeadingDecorated';
import Seo from '../../components/Seo';
import mailImg from '../../images/mail.svg';
import downloadImg from '../../images/download.svg';

export const query = graphql`
  {
    strapiContact(
      localizations: {
        data: { elemMatch: { attributes: { locale: { eq: "en" } } } }
      }
    ) {
      localizations {
        data {
          attributes {
            addressSegment1
            addressSegment2
            email
          }
        }
      }
    }

    downloads: allStrapiContactFile(
      filter: { locale: { eq: "en" } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        name
        file {
          localFile {
            url
          }
        }
      }
    }
  }
`;

const ContactPage = ({ data }) => {
  const node = data.strapiContact.localizations.data[0].attributes;
  const files = data.downloads.nodes;

  return (
    <Layout locale="en">
      <Seo title="Contact" locale="en" />
      <div className="contact">
        <section className="contact__section">
          <HeadingDecorated className="contact__heading">
            Contact
          </HeadingDecorated>
          <h3>Address</h3>
          <div className="contact__address">
            <Markdown>{node.addressSegment1}</Markdown>
            <Markdown>{node.addressSegment2}</Markdown>
          </div>
          <a href={`mailto:${node.email}`} className="contact__email">
            <img src={mailImg} alt="e-mail" />
            {node.email}
          </a>
        </section>
        <section>
          <HeadingDecorated className="contact__heading">
            Download files
          </HeadingDecorated>
          <h3>Documents</h3>
          <div className="contact__downloads">
            {files.map((file) => (
              <div
                className="contact__download-item"
                key={file.file.localFile.url}
              >
                <p>{file.name}</p>
                <a
                  href={file.file.localFile.url}
                  className="contact__download-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={downloadImg} alt="download" />
                  Download
                </a>
              </div>
            ))}
          </div>

          <p className="contact__authors">Designed by Zofia Lasocka</p>
          <p className="contact__authors">Implemented by Sebastian Koszuta</p>
        </section>
      </div>
    </Layout>
  );
};

export default ContactPage;
